#backpack{
    position: fixed;
    display: flex;
    /* border: solid 1px grey; */
    bottom: 0px;
    left: 40px;
    /* min-width: 400px; */
    /* height: 60px; */
    /* background-color: rgb(107, 90, 90); */
    /* background-image: url(../img/backpackSpace.png); */
    /* background-size: contain; */
}

.backpackSpace {
    width: 60px;
    height: 60px;
    background-image: url(../img/backpack/space2.png);
    background-size: cover;
}

.backpackSpaceContent {
    width: 50px;
    height: 50px;
    margin: 5px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    /* background-color: gray; */
}

.backpackSpaceContentBague {
    background-image: url(../img/backpack/bague.png);
}

.backpackSpaceContentKey {
    background-image: url(../img/backpack/key.png);
}

.backpackSpaceContentBook {
    background-image: url(../img/backpack/book.png);
}

.backpackSpaceContentSpeedPotion {
    background-image: url(../img/backpack/speed_potion.png);
}

.backpackSpaceContentUnfreezePotion {
    background-image: url(../img/backpack/unfreeze_potion.png);
}

.backpackHammer {
    background-image: url(../img/backpack/hammer.png);
}


.backpackSpaceProgressBar {
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(210, 104, 72, 0.5);
  }


.objectContainer {
    border: 1px solid black;
    position: relative;
    height: 42px;
    width: 42px;
    margin: 5px;
    padding: 4px;
}

.objectContainer:hover {
    border: 1px solid gray;
}

.object{
    height: 40px;
    width: 40px;
}

.infobox {
    position: absolute;
    padding: 5px;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.641);
    bottom: 60px;
    left: 0;
    display: none;
}

img.object{
    object-fit: cover;
}