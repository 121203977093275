#backpack {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 40px;
}

.backpackSpace {
  width: 60px;
  height: 60px;
  background-image: url("space2.52940487.png");
  background-size: cover;
}

.backpackSpaceContent {
  width: 50px;
  height: 50px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 5px;
}

.backpackSpaceContentBague {
  background-image: url("bague.a6844ef8.png");
}

.backpackSpaceContentKey {
  background-image: url("key.d849dd3d.png");
}

.backpackSpaceContentBook {
  background-image: url("book.1e1a3dd9.png");
}

.backpackSpaceContentSpeedPotion {
  background-image: url("speed_potion.35a03c75.png");
}

.backpackSpaceContentUnfreezePotion {
  background-image: url("unfreeze_potion.c49ef222.png");
}

.backpackHammer {
  background-image: url("hammer.1c424467.png");
}

.backpackSpaceProgressBar {
  z-index: 1;
  height: 100%;
  background-color: #d2684880;
  position: relative;
  top: 0;
  left: 0;
}

.objectContainer {
  height: 42px;
  width: 42px;
  border: 1px solid #000;
  margin: 5px;
  padding: 4px;
  position: relative;
}

.objectContainer:hover {
  border: 1px solid gray;
}

.object {
  height: 40px;
  width: 40px;
}

.infobox {
  width: 200px;
  background-color: #000000a3;
  padding: 5px;
  display: none;
  position: absolute;
  bottom: 60px;
  left: 0;
}

img.object {
  object-fit: cover;
}

/*# sourceMappingURL=index.82cbefbb.css.map */
